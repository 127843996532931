import React, { useEffect, useState, useCallback } from "react"
import * as styles from "./list.module.css"
import Pagenation from "components/tools/pagination.js"
import Layout from "components/common/layout.js"
import { navigate } from "gatsby-link"
import { getDateYMD, getThumb } from "utils"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { getClassRoomListAsync } from "store/modules/classRoomReducer.js"
import Menu from "components/class/room/menu.js"
import OrderMenu from "components/tools/orderMenu"
import queryString from "query-string"
const List = props => {
  const dispatch = useDispatch()
  const getClassRoomList = useCallback(
    (currentPage, pagePostSize, order) =>
      dispatch(getClassRoomListAsync(currentPage, pagePostSize, order)),
    [dispatch]
  )

  const { classRoomList, total } = useSelector(
    ({ classRoomReducer }) => classRoomReducer,
    shallowEqual
  )

  const { page } = queryString.parse(props.location.search)
  const [order, setOrder] = useState(1)

  const [currentPage, setCurrentPage] = useState(page ? Number(page) : 1)
  const pagePostSize = 5

  useEffect(() => {
    ;(async () => {
      getClassRoomList(currentPage, pagePostSize, order)
    })()
  }, [currentPage, order])

  console.log(classRoomList)

  return (
    <Layout>
      <div>
        <div className={styles.title}>
          <p className={`subtitle`}>
            피치서가 교사연구단이 들려주는 교실 이야기
          </p>
        </div>
        <OrderMenu
          order={order}
          setOrder={setOrder}
          setCurrentPage={setCurrentPage}
        />
        <div className={`colContainer ${styles.main}`}>
          {classRoomList.map(post => (
            <Menu
              key={post.talk_no}
              img={getThumb(post.thumb_title_uuid)}
              title={post.title}
              text={post.introText}
              like={post.like}
              hit={post.hit}
              date={getDateYMD(post.publishTime, "YYYY.MM.DD")}
              writer={post?.EduTalkWriter?.name}
              onClick={() => {
                navigate(
                  `/class/room/detail/?talk_no=${post.talk_no}&currentPage=${currentPage}&pagePostSize=${pagePostSize}`
                )
              }}
            />
          ))}
          <div></div>
        </div>
        <Pagenation
          total={total}
          pagePostSize={pagePostSize}
          pageSize={10}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </Layout>
  )
}

export default List
